
import { defineComponent } from "vue";
import { IonText, IonPopover, IonIcon, IonBadge, IonButton } from "@ionic/vue";
import { ellipsisVertical } from "ionicons/icons";
import PopoverMenuPkg from "@/components/functional/order/PopoverMenuPkg.vue";

export default defineComponent({
  name: "OrderPkgCard",

  components: {
    IonText,
    IonPopover,
    IonIcon,
    IonBadge,
    IonButton,
    PopoverMenuPkg,
  },

  setup() {
    return { ellipsisVertical };
  },

  props: {
    menu: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isOpenPopoverMenuPkg: false,
      eventPopoverMenuPkg: null,
    };
  },

  methods: {
    openDetail(status: string) {
      this.$router.push("/order/checkout/"+this.menu.trx_id);
    },

    openPopoverMenuPkg(state: boolean, ev?: any) {
      this.eventPopoverMenuPkg = ev;
      this.isOpenPopoverMenuPkg = state;
    },
  },
});
