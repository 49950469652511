
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonText,
  toastController,
} from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import OrderPkgCard from "@/components/functional/order/OrderPkgCard.vue";
import axios from "axios";

export default defineComponent({
  name: "Order",

  components: {
    IonPage,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonText,
    PageHeader,
    OrderPkgCard,
  },

  data() {
    return {
      activeTab: "UNPAID",
      menus: [
        // {
        //   name: "Paket 2",
        //   img: "https://lorempixel.com/128/112/food",
        //   status: "UNPAID",
        //   delivery: {
        //     timeStart: "09.00",
        //     timeFinish: "12.00",
        //   },
        // },
        // {
        //   name: "Paket 3",
        //   img: "https://lorempixel.com/128/112/food",
        //   status: "UNPAID",
        //   delivery: {
        //     timeStart: "09.00",
        //     timeFinish: "12.00",
        //   },
        // },
      ] as any,
    };
  },
  mounted() {
    this.getUnpaidOrder();
  },

  watch: {
    activeTab(val: any) {
      this.menus = [];
      if (val == "UNPAID") {
        this.getUnpaidOrder();
      } else if (val == "PROCESS") {
        this.getProcessOrder();
      } else if (val == "DONE") {
        this.getDoneOrder();
      }
    },
  },

  methods: {
    async refresh(event: any) {
      if (this.activeTab == "UNPAID") {
        await this.getUnpaidOrder();
      } else if (this.activeTab == "PROCESS") {
        await this.getProcessOrder();
      } else if (this.activeTab == "DONE") {
        await this.getDoneOrder();
      }

      event.target.complete();
    },
    async getUnpaidOrder() {
      await axios
        .get("unpaid")
        .then(({ data }) => {
          this.menus = []
          data.data.forEach((e: any) => {
            this.menus.push({
              status: "UNPAID",
              nama: e.nama,
              jadwal: e.jadwal,
              waktu: e.waktu,
              img: e.img,
              trx_id: e.trx_id
            });
          });
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
        });
    },
    async getProcessOrder() {
      await axios
        .get("paid")
        .then(({ data }) => {
          this.menus = []
          data.data.forEach((e: any) => {
            this.menus.push({
              status: "PROCESS",
              nama: e.nama,
              jadwal: e.jadwal,
              waktu: e.waktu,
              img: e.img,
              trx_id: e.trx_id
            });
          });
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
        });
    },
    async getDoneOrder() {
      await axios
        .get("order-completed")
        .then(({ data }) => {
          this.menus = []
          data.data.forEach((e: any) => {
            this.menus.push({
              status: "SUCCESS",
              nama: e.nama,
              jadwal: e.jadwal,
              waktu: e.waktu,
              img: e.img,
              trx_id: e.trx_id
            });
          });
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
        });
    },
    setStatus(ev: any) {
      this.activeTab = ev.detail.value;
      localStorage.setItem("orderDetailStatus", ev.detail.value);
    },
  },
});
