<template>
  <ion-content>
    <ion-list>
      <ion-item class="ion-activatable ripple-parent cursor-pointer" @click="$emit('openDetail')">
        <ion-ripple-effect />
        <ion-text color="dark">Lihat detail</ion-text>
      </ion-item>
      <ion-item class="ion-activatable ripple-parent cursor-pointer" @click="$emit('reOrder')">
        <ion-ripple-effect />
        <ion-text color="dark">Pesan lagi</ion-text>
      </ion-item>
      <ion-item class="ion-activatable ripple-parent cursor-pointer" @click="$emit('createReview')">
        <ion-ripple-effect />
        <ion-text color="dark">Buat review</ion-text>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { IonContent, IonList, IonItem, IonRippleEffect, IonText } from '@ionic/vue'

export default defineComponent({
  name: 'PopoverMenuPkg',

  components: { IonContent, IonList, IonItem, IonRippleEffect, IonText }
})
</script>